import { EmployeePricingType } from "../../../common/constants"
import {
    phoneNumberFormatter,
    titleCaseFormatter,
    filterFormatter,
    isActiveValueFormatter,
} from "../../../common/ag-grid-value-formatters"
import { arrayOfReferenceablesValueGetter } from "../../../common/ag-grid-value-getters"
import { currencyColDefCreator } from "./standard-col-def-creators"
import { companyGroupsFilterDef, singleProjectFilterDef } from "../../../filters/filter-defs"
import {
    checkboxColDefCreator,
    stringColDefCreator,
    booleanColDefCreator,
    employeeColDefCreator,
} from "./standard-col-def-creators"
import { getFlagEnabled } from "../../../getFlagValue"

export function getProjectEmployeesSettings(featureFlags, currentProject, currentUser, __, rowData) {
    const showPremiumPricing =
        featureFlags.pricing && currentProject
            ? currentProject.options.employee_pricing_type == EmployeePricingType.PREMIUM_TYPE
            : false
    const showStandardPricing =
        showPremiumPricing ||
        (featureFlags.pricing &&
            currentProject &&
            currentProject.options.employee_pricing_type == EmployeePricingType.STANDARD_TYPE)
    const showLaborType = featureFlags.cost_code_controls
    const pinned = { pinned: "left" }

    const textFieldOptions =
        currentUser && currentUser.text_field_options
            ? currentUser.text_field_options.filter(option => option.model === "Employee")
            : []

    const textFieldOptionColDefs = textFieldOptions.map(option =>
        stringColDefCreator({
            headerName: option.label,
            field: `/custom_fields/${option.name}`,
            editable: false,
            referenceableMap: {
                referenceableKey: "employees",
                rowKey: "employee",
            },
        })
    )

    const settings = {
        tableName: "Project Team Members",
        navId: "project-members",
        resources: ["projectEmployees"],
        filters: [companyGroupsFilterDef, singleProjectFilterDef],
        relatedNames: {
            projectEmployees: {
                employees: ["/employee"],
            },
        },
        colDefs: [
            {
                field: "/project",
                hide: true,
            },
            checkboxColDefCreator({ ...pinned }),
            {
                ...employeeColDefCreator({
                    editable: true,
                    idsToExclude:
                        rowData && rowData.sourceData && rowData.sourceData.projectEmployees
                            ? rowData.sourceData.projectEmployees.map(r => r.employee)
                            : [],
                    excludeFromExport: true,
                }),
                invertRelatedFilters: ["projectId"],
                ...pinned,
            },
            stringColDefCreator({
                headerName: "Employee ID*",
                field: "/company_supplied_id",
                headerTooltip: "Your internal company id",
                required: true,
                referenceableMap: {
                    referenceableKey: "employees",
                    rowKey: "employee",
                },
                ...pinned,
            }),
            stringColDefCreator({
                headerName: "First Name*",
                field: "/first_name",
                referenceableMap: {
                    referenceableKey: "employees",
                    rowKey: "employee",
                },
            }),
            stringColDefCreator({
                headerName: "Last Name*",
                field: "/last_name",
                referenceableMap: {
                    referenceableKey: "employees",
                    rowKey: "employee",
                },
            }),
            {
                ...stringColDefCreator({
                    headerName: "Role",
                    field: "/user_role",
                    referenceableMap: {
                        referenceableKey: "employees",
                        rowKey: "employee",
                    },
                    width: 120,
                }),
                valueFormatter: titleCaseFormatter,
                customTooltip: undefined,
                cellClass: undefined,
            },
            {
                ...stringColDefCreator({
                    headerName: "Mobile Phone",
                    field: "/phone",
                    referenceableMap: {
                        referenceableKey: "employees",
                        rowKey: "employee",
                    },
                    headerTooltip: "Required for Foremen",
                }),
                valueFormatter: phoneNumberFormatter,
            },
            stringColDefCreator({
                headerName: "Email",
                field: "/email",
                referenceableMap: {
                    referenceableKey: "employees",
                    rowKey: "employee",
                },
                width: 230,
                headerTooltip: "Required for roles other than Worker",
            }),
            stringColDefCreator({
                headerName: "Classification",
                field: "/classification",
                referenceableMap: {
                    referenceableKey: "employees",
                    rowKey: "employee",
                },
            }),
            stringColDefCreator({
                headerName: "Trade",
                field: "/trade",
                referenceableMap: {
                    referenceableKey: "employees",
                    rowKey: "employee",
                },
            }),
            stringColDefCreator({
                headerName: "Labor Types",
                editable: false,
                field: "/crew_types",
                hide: !showLaborType,
                valueGetter: params => arrayOfReferenceablesValueGetter(params, "name", ", "),
                referenceableMap: {
                    referenceableKey: "employees",
                    rowKey: "employee",
                },
            }),
            ...(showStandardPricing
                ? [
                      {
                          ...currencyColDefCreator({
                              headerName: "ST",
                              field: "/standard_time",
                              required: true,
                          }),
                          editable: true,
                      },
                      {
                          ...currencyColDefCreator({
                              headerName: "OT",
                              field: "/over_time",
                              required: true,
                          }),
                          editable: true,
                      },
                      {
                          ...currencyColDefCreator({
                              headerName: "DT",
                              field: "/double_time",
                              required: true,
                          }),
                          editable: true,
                      },
                  ]
                : []),
            ...(showPremiumPricing
                ? [
                      {
                          ...currencyColDefCreator({
                              headerName: "POT",
                              field: "/premium_over_time",
                              required: true,
                          }),
                          editable: true,
                      },
                      {
                          ...currencyColDefCreator({
                              headerName: "PDT",
                              field: "/premium_double_time",
                              required: true,
                          }),
                          editable: true,
                      },
                  ]
                : []),
            {
                ...booleanColDefCreator({
                    headerName: "Status",
                    field: "/is_active",
                    referenceableMap: {
                        referenceableKey: "employees",
                        rowKey: "employee",
                    },
                    minWidth: 100,
                    editable: false,
                }),
                valueFormatter: isActiveValueFormatter,
            },
            ...textFieldOptionColDefs,
        ],
        gridSettings: {
            rowHeight: 40,
            defaultColDef: {
                editable: false,
                getQuickFilterText: filterFormatter,
            },
            rowSelection: "multiple",
            // allow our click events to happen
            suppressRowClickSelection: true,
        },
        otherSettings: {
            pageTitle: "Project Team Members",
            subcontent: `Manage your workforce by assigning them to project(s).
                FOREMEN listed here may submit time cards for this project in the web app.
                Assign WORKERS to this project to make it easier for Foremen to add these workers to their crews.`,
            rowLevelErrorDisplay: true,
            enableSearchBar: true,
            hiddenColumnDefaults: {
                project: "projectId",
            },
            buttons: {
                cell: [],
                row: [
                    {
                        label: "Remove",
                        icon: "delete",
                        action: "deleteSelectedRows",
                    },
                    ...(getFlagEnabled("WA-8743-cico-qr-pdfs")
                        ? [
                              {
                                  label: "Print QR Code PDF",
                                  action: "openPrintQRModal",
                                  disabled: context => {
                                      // employee pricing (project assignments) don't store cico qr codes
                                      // so correlate the row's employee id with the referenced employee data
                                      // and make sure at least one employee has a qr code
                                      const relatedEmpData = context?.referenceableData?.employees ?? {}
                                      return context.selectedRows.every(
                                          row => !relatedEmpData?.[row.employee]?.cico_qr_code
                                      )
                                  },
                                  tooltip: context => {
                                      const relatedEmpData = context?.referenceableData?.employees ?? {}
                                      if (
                                          context.selectedRows.every(
                                              row => !relatedEmpData?.[row.employee]?.cico_qr_code
                                          )
                                      )
                                          return "None of the selected employees have QR codes"
                                      return "Print QR code PDFs for selected employees"
                                  },
                              },
                          ]
                        : []),
                ],
                table: [
                    {
                        label: "Add Row",
                        icon: "add",
                        action: "addNewRow",
                    },
                    {
                        label: "Upload",
                        icon: "upload",
                        action: "navigateTo",
                        args: {
                            url: "/rhumbix/projects/employees/upload/",
                        },
                    },
                    {
                        label: "Export ▾",
                        icon: "export",
                        action: "export",
                    },
                ],
            },
        },
    }

    return settings
}
