import { combineReducers } from "redux"
import {
    ABSENCE_MODAL_CLOSED,
    ABSENCE_MODAL_OPENED,
    ADD_COHORT_EMPLOYEES_CLOSED,
    ADD_COHORT_EMPLOYEES_OPENED,
    ADD_EDIT_WORK_SHIFT_MODAL_CLOSED,
    ADD_EDIT_WORK_SHIFT_MODAL_OPENED,
    ADD_PICKLIST_ITEM_MODAL_CLOSED,
    ADD_PICKLIST_ITEM_MODAL_OPENED,
    ADD_QUANTITY_MODAL_CLOSED,
    ADD_QUANTITY_MODAL_OPENED,
    ADD_ROWS_TO_WEEKLY_TK_MODAL_CLOSED,
    ADD_ROWS_TO_WEEKLY_TK_MODAL_OPENED,
    ADD_WORKER_MODAL_CHANGED_SELECTED_WORKER_ID,
    ADD_WORKER_MODAL_CLOSED,
    ADD_WORKER_MODAL_OPENED,
    ADD_WORKSHIFT_MODAL_CLOSED, // Daily TK
    ADD_WORKSHIFT_MODAL_OPENED,
    BUNDLE_EDIT_WARNING_MODAL_CLOSE,
    BUNDLE_EDIT_WARNING_MODAL_OPEN,
    CICO_QR_CODE_MODAL_CLOSED,
    CICO_QR_CODE_MODAL_OPENED,
    CLOSE_WORK_COMPONENT_SAVE_ERROR_MODAL,
    COPY_TO_MODAL_CLOSE,
    COPY_TO_MODAL_OPEN,
    CREATE_SAVED_FILTER_MODAL_CLOSED,
    CREATE_SAVED_FILTER_MODAL_OPENED,
    CREATE_SAVED_FILTER_SET_FAILED,
    CSV_ERROR_MODAL_CLOSED,
    CSV_ERROR_MODAL_OPENED,
    CUSTOM_FORM_ERROR_MODAL_CLOSED,
    CUSTOM_FORM_ERROR_MODAL_OPENED,
    CUSTOM_FORM_GUEST_SAVE_MODAL_CLOSED,
    CUSTOM_FORM_GUEST_SAVE_MODAL_OPENED,
    CUSTOM_FORM_SAVE_MODAL_CLOSED,
    CUSTOM_FORM_SAVE_MODAL_OPENED,
    CUSTOM_FORM_UI_CHANGE_MODAL_CLOSED,
    CUSTOM_FORM_UI_CHANGE_MODAL_OPENED,
    DELETE_CONFIRMATION_MODAL_CLOSED,
    DELETE_CONFIRMATION_MODAL_OPENED,
    DELETE_SAVED_FILTER_MODAL_CLOSED,
    DELETE_SAVED_FILTER_MODAL_OPENED,
    DELETE_SAVED_FILTER_SET_FAILED,
    DELETE_WORKSHIFT_MODAL_CLOSED,
    DELETE_WORKSHIFT_MODAL_OPENED,
    DUPLICATE_INACTIVE_WARNING_MODAL_CLOSED,
    DUPLICATE_INACTIVE_WARNING_MODAL_OPENED,
    DUPLICATE_WORKSHIFT_MODAL_CLOSED,
    DUPLICATE_WORKSHIFT_MODAL_OPENED,
    EDIT_WORK_COMPONENT_MODAL_CLOSED,
    EDIT_WORK_COMPONENT_MODAL_OPENED,
    ERROR_MODAL_CLOSED,
    ERROR_MODAL_OPENED,
    FORM_SHARE_MODAL_CLOSED,
    FORM_SHARE_MODAL_OPENED,
    GUEST_INFO_ERROR_MODAL_CLOSED,
    GUEST_INFO_ERROR_MODAL_OPENED,
    GUEST_INFO_MODAL_CLOSED,
    GUEST_INFO_MODAL_OPENED,
    IMPORT_TO_FIELD_FORM_MODAL_CLOSE,
    IMPORT_TO_FIELD_FORM_MODAL_OPEN,
    LEAVE_CONFIRMATION_MODAL_CLOSED,
    LEAVE_CONFIRMATION_MODAL_OPENED,
    LEAVE_FIELD_FORM_MODAL_CLOSED,
    LEAVE_FIELD_FORM_MODAL_OPEN,
    OPEN_WORK_COMPONENT_SAVE_ERROR_MODAL,
    PASSWORD_CHANGE_MODAL_CLOSED,
    PASSWORD_CHANGE_MODAL_OPENED,
    PROJECT_CONFIG_MODAL_CLOSED,
    PROJECT_CONFIG_MODAL_OPENED,
    SET_SELECTED_TRANSFORMS_VALUE,
    SET_TRANSFORMS_OPTIONS,
    SIGNATURE_REQUIRED_MODAL_CLOSED,
    SIGNATURE_REQUIRED_MODAL_OPENED,
    SSO_CONFIGURATION_CONFIRMATION_MODAL_CLOSED,
    SSO_CONFIGURATION_CONFIRMATION_MODAL_OPENED,
    tAbsenceModalActions,
    tAddCohortEmployeesModalActions,
    tAddEditWorkShiftModalActions,
    tAddPicklistItemModalActions,
    tAddQuantityModalActions,
    tAddRowsToWeeklyTKModalActions,
    tAddWorkerModalActions,
    tAddWorkshiftModalActions, // Daily TK
    tBundleEditWarningActions,
    tChangePasswordModalActions,
    tCicoQRCodeLightboxModalAction,
    tCopyToActions,
    tCreateSavedFilterModalActions,
    tCsvErrorModalActions,
    tCustomFormModalActions,
    tDeleteConfirmationModalActions,
    tDeleteSavedFilterModalActions,
    tDeleteWorkshiftModalActions,
    tDuplicateInactiveWarningModalActions,
    tDuplicateWorkshiftModalActions,
    tEditWorkComponentModalActions,
    tErrorModalActions,
    tFormShareModalActions,
    tGuestInfoActions,
    tImportToFieldFormModalActions,
    TK_SIGN_TIMECARD_MODAL_CLOSED,
    TK_SIGN_TIMECARD_MODAL_OPENED,
    tLeaveConfirmationModalActions,
    tLeaveFieldFormModalActions,
    tProjectConfigModalActions,
    tSignatureRequiredModalActions,
    tSSOConfigurationConfirmationModalActions,
    tTKSignTimecardModalActions,
    tUpdateWorkerModalActions,
    tWalkThroughModalActions,
    tWeeklyAddWorkShiftModalActions, // Weekly TK
    WALK_THROUGH_MODAL_CLOSED,
    WALK_THROUGH_MODAL_OPENED,
    WEEKLY_ADD_WORK_SHIFT_MODAL_CLOSED, // Weekly TK
    WEEKLY_ADD_WORK_SHIFT_MODAL_OPENED,
    WORK_COMPONENT_EDITED,
} from "./actions"
import { tCopyToModalState, tImportToFieldFormModalState, tLeaveFieldFormState } from "./types"
import {
    CUSTOM_FORM_ITEM_SAVE_COMPLETE,
    CUSTOM_FORM_ITEM_SAVE_ERROR,
    iCustomFormSaveErrorNotification,
    iRemoveCustomFormItemFromSaveList,
} from "../../actions/form-view-actions"
import { iEmployee } from "../../cached-data/types"

/**
 * Add Quantitiy Modal
 */

const initAddQuantityModal = {
    isOpen: false,
}

const modalAddQuantity = (state = initAddQuantityModal, action: tAddQuantityModalActions) => {
    switch (action.type) {
        case ADD_QUANTITY_MODAL_OPENED:
            return { ...state, isOpen: true }
        case ADD_QUANTITY_MODAL_CLOSED:
            return { ...state, isOpen: false }
        default:
            return state
    }
}

/**
 * Confirmation Modal
 */

const initStateConfirmation = { leave_confirmation_open: false }

const modalConfirmation = (state = initStateConfirmation, action: tLeaveConfirmationModalActions) => {
    switch (action.type) {
        case LEAVE_CONFIRMATION_MODAL_OPENED:
            return { ...state, leave_confirmation_open: true }
        case LEAVE_CONFIRMATION_MODAL_CLOSED:
            return { ...state, leave_confirmation_open: false }
        default:
            return state
    }
}

/**
 * Absense Modal
 */

const initStateAbsence = {
    // FIXME(JSG): Remove parent level 'absence' and update references
    absence: {
        open: false,
        employeeWorkshift: null,
    },
}

const modalAbsence = (state = initStateAbsence, action: tAbsenceModalActions) => {
    switch (action.type) {
        case ABSENCE_MODAL_OPENED:
            return {
                ...state,
                absence: {
                    open: true,
                    employeeWorkshift: action.employeeWorkshift,
                },
            }
        case ABSENCE_MODAL_CLOSED:
            return { ...state, absence: { open: false, employeeWorkshift: null } }
        default:
            return state
    }
}

const initStateProjectConfig = {
    config: {
        open: false,
    },
}
const modalProjectConfig = (state = initStateProjectConfig, action: tProjectConfigModalActions) => {
    switch (action.type) {
        case PROJECT_CONFIG_MODAL_OPENED:
            return {
                ...state,
                config: {
                    open: true,
                    newAssignments: action.newAssignments,
                    newRemovals: action.newRemovals,
                    saveCallback: action.saveCallback,
                },
            }
        case PROJECT_CONFIG_MODAL_CLOSED:
            return { ...state, config: { open: false } }
        default:
            return state
    }
}

/**
 * Walkthrough Modal
 */

const initStateWalkthrough = {
    // FIXME(JSG): Remove parent level 'walk_through' and update references
    walk_through: {
        open: false,
        already_seen: false,
    },
}

const modalWalkthrough = (state = initStateWalkthrough, action: tWalkThroughModalActions) => {
    switch (action.type) {
        case WALK_THROUGH_MODAL_OPENED:
            return { ...state, walk_through: { open: true, already_seen: false } }
        case WALK_THROUGH_MODAL_CLOSED:
            return { ...state, walk_through: { open: false, already_seen: true } }
        default:
            return state
    }
}

/**
 * Work Component Modal
 */

const initStateWorkComponent = {
    // FIXME(JSG): Remove parent level 'editWorkComponent' and update references
    editWorkComponent: {
        open: false,
        workComponent: null,
        worker: null,
        newST: null,
        newOT: null,
        newDT: null,
        newTotal: null,
    },
}

const modalWorkComponent = (state = initStateWorkComponent, action: tEditWorkComponentModalActions) => {
    switch (action.type) {
        case EDIT_WORK_COMPONENT_MODAL_OPENED:
            const workComponent = action.workComponent
            return {
                ...state,
                editWorkComponent: {
                    open: true,
                    workComponent: workComponent,
                    employeeWorkShiftId: action.employeeWorkShiftId,
                    worker: action.worker,
                    costCode: action.costCode,
                    newST: {
                        value: workComponent.adjusted_minutes_st,
                        valid: true,
                    },
                    newOT: {
                        value: workComponent.adjusted_minutes_ot,
                        valid: true,
                    },
                    newDT: {
                        value: workComponent.adjusted_minutes_dt,
                        valid: true,
                    },
                    newTotal: workComponent.totalMinutes(),
                },
            }

        case EDIT_WORK_COMPONENT_MODAL_CLOSED:
            return { ...state, editWorkComponent: initStateWorkComponent.editWorkComponent }

        case WORK_COMPONENT_EDITED:
            return {
                ...state,
                editWorkComponent: {
                    ...state.editWorkComponent,
                    newST:
                        action.wcType == "ST"
                            ? { value: action.value, valid: action.valid }
                            : state.editWorkComponent.newST,
                    newOT:
                        action.wcType == "OT"
                            ? { value: action.value, valid: action.valid }
                            : state.editWorkComponent.newOT,
                    newDT:
                        action.wcType == "DT"
                            ? { value: action.value, valid: action.valid }
                            : state.editWorkComponent.newDT,
                    newTotal: action.total,
                },
            }
        default:
            return state
    }
}

/**
 * Add Worker Modal
 */

const initStateAddWorker = {
    open: false,
    workShift: null,
    selectedWorker: null,
}

const modalAddWorker = (state = initStateAddWorker, action: tAddWorkerModalActions | tUpdateWorkerModalActions) => {
    switch (action.type) {
        case ADD_WORKER_MODAL_OPENED:
            return { ...state, open: true, workShift: action.workShift }
        case ADD_WORKER_MODAL_CLOSED:
            return { ...state, open: false, workShift: null, selectedWorker: null }
        case ADD_WORKER_MODAL_CHANGED_SELECTED_WORKER_ID:
            return { ...state, selectedWorker: action.selectedWorker }
        default:
            return state
    }
}

/**
 * Add Workshift Modal
 */

const initStateAddWorkshift = {
    isOpen: false,
    date: null,
    selectedProjects: [],
}

const modalAddWorkshift = (state = initStateAddWorkshift, action: tAddWorkshiftModalActions) => {
    switch (action.type) {
        case ADD_WORKSHIFT_MODAL_OPENED:
            return { ...state, isOpen: true }
        case ADD_WORKSHIFT_MODAL_CLOSED:
            return { ...state, isOpen: false }
        default:
            return state
    }
}

/**
 * Duplicate Workshift Modal
 */

const initStateDuplicateWorkshift = {
    isOpen: false,
    workshiftId: null,
}

const modalDuplicateWorkshift = (state = initStateDuplicateWorkshift, action: tDuplicateWorkshiftModalActions) => {
    switch (action.type) {
        case DUPLICATE_WORKSHIFT_MODAL_OPENED:
            return { ...state, isOpen: true, workshiftId: action.workshiftId }
        case DUPLICATE_WORKSHIFT_MODAL_CLOSED:
            return initStateDuplicateWorkshift
        default:
            return state
    }
}

/**
 * Duplicate Inactive Warning Modal
 */

const initStateDuplicateInactiveWarning = {
    isOpen: false,
    workshiftId: null,
}

const modalDuplicateInactiveWarning = (
    state = initStateDuplicateInactiveWarning,
    action: tDuplicateInactiveWarningModalActions
) => {
    switch (action.type) {
        case DUPLICATE_INACTIVE_WARNING_MODAL_OPENED:
            return { ...state, isOpen: true, workshiftId: action.workshiftId }
        case DUPLICATE_INACTIVE_WARNING_MODAL_CLOSED:
            return initStateDuplicateInactiveWarning
        default:
            return state
    }
}

/**
 * Signature Required Modal
 */

const initStateSignatureRequired = {
    open: false,
}

const modalSignatureRequired = (state = initStateSignatureRequired, action: tSignatureRequiredModalActions) => {
    switch (action.type) {
        case SIGNATURE_REQUIRED_MODAL_OPENED:
            return { ...state, open: true }
        case SIGNATURE_REQUIRED_MODAL_CLOSED:
            return { ...state, open: false }
        default:
            return state
    }
}

/**
 * Delete Workshift Modal
 */

const initStateDeleteWorkshift = {
    open: false,
    workshiftId: null,
    ewsCount: null,
}

const modalDeleteWorkshift = (state = initStateDeleteWorkshift, action: tDeleteWorkshiftModalActions) => {
    switch (action.type) {
        case DELETE_WORKSHIFT_MODAL_OPENED:
            return { ...state, open: true, workshiftId: action.workshiftId, ewsCount: action.ewsCount }
        case DELETE_WORKSHIFT_MODAL_CLOSED:
            return initStateDeleteWorkshift
        default:
            return state
    }
}

/**
 * Change Password Modal
 */

const initStateChangePassword = {
    open: false,
}

const modalChangePassword = (state = initStateChangePassword, action: tChangePasswordModalActions) => {
    switch (action.type) {
        case PASSWORD_CHANGE_MODAL_OPENED:
            return { ...state, open: true }
        case PASSWORD_CHANGE_MODAL_CLOSED:
            return initStateChangePassword
        default:
            return state
    }
}

/**
 * CSV Error Modal
 */

const initStateCsvError = {
    open: false,
    errors: [],
}

const modalCsvError = (state = initStateCsvError, action: tCsvErrorModalActions) => {
    switch (action.type) {
        case CSV_ERROR_MODAL_OPENED:
            return { ...state, open: true, errors: action.errors }
        case CSV_ERROR_MODAL_CLOSED:
            return initStateCsvError
        default:
            return state
    }
}

/**
 * Guest Info Modal
 */
const initGuestInfo = {
    open: false,
    guestEmail: "",
    signedKey: "",
}

const modalGuestInfo = (state = initGuestInfo, action: tGuestInfoActions) => {
    switch (action.type) {
        case GUEST_INFO_MODAL_OPENED:
            return { ...state, open: true, guestEmail: action.guestEmail, signedKey: action.signedKey }
        case GUEST_INFO_MODAL_CLOSED:
            return { ...state, open: false }
        default:
            return state
    }
}

/**
 * Guest Info Error Modal
 */
const initGuestInfoError = {
    open: false,
    errors: [],
}

const modalGuestInfoError = (state = initGuestInfoError, action: tGuestInfoActions) => {
    switch (action.type) {
        case GUEST_INFO_ERROR_MODAL_OPENED:
            return { ...state, open: true, error: action.error }
        case GUEST_INFO_ERROR_MODAL_CLOSED:
            return { ...state, open: false }
        default:
            return state
    }
}

/**
 * Custom Form Save Modal
 */

const initCustomFormSave = {
    open: false,
    itemsToSave: [],
    errors: [],
}

const modalCustomFormSave = (
    state = initCustomFormSave,
    action: tCustomFormModalActions | iRemoveCustomFormItemFromSaveList | iCustomFormSaveErrorNotification
) => {
    switch (action.type) {
        case CUSTOM_FORM_SAVE_MODAL_OPENED:
            return { ...state, open: true, itemsToSave: action.ids }
        case CUSTOM_FORM_ITEM_SAVE_COMPLETE:
            // filter out the ids of stores that have successfully saved
            const remainingItems = state["itemsToSave"].filter(id => id !== action.id)
            return { ...state, itemsToSave: remainingItems }
        case CUSTOM_FORM_ITEM_SAVE_ERROR:
            return { ...state, errors: action.data }
        case CUSTOM_FORM_SAVE_MODAL_CLOSED:
            return initCustomFormSave
        default:
            return state
    }
}

const initCustomFormGuestSave = {
    open: false,
    storeId: -1,
    validatedData: null,
    currentStatus: null,
    nextStatuses: [],
    guestEmail: null,
    signedKey: null,
    guestLastName: null,
    guestExpiration: -1,
}

const modalCustomFormGuestSave = (
    state = initCustomFormGuestSave,
    action: tCustomFormModalActions | iRemoveCustomFormItemFromSaveList | iCustomFormSaveErrorNotification
) => {
    switch (action.type) {
        case CUSTOM_FORM_GUEST_SAVE_MODAL_OPENED:
            return {
                ...state,
                open: true,
                storeId: action.id,
                validatedData: action.validatedData,
                currentStatus: action.currentStatus,
                nextStatuses: action.statuses,
                guestEmail: action.guestEmail,
                signedKey: action.signedKey,
                guestLastName: action.guestLastName,
                guestExpiration: action.guestExpiration,
            }
        case CUSTOM_FORM_GUEST_SAVE_MODAL_CLOSED:
            return initCustomFormSave
        default:
            return state
    }
}

/**
 * Copy To Modal
 */

const initStateCopyTo = {
    copyToModalOpen: false,
    transformsOptions: [],
    selectedTransformValue: 0,
    copyToModalParams: undefined,
}

const modalCopyTo = (state: tCopyToModalState = initStateCopyTo, action: tCopyToActions) => {
    switch (action.type) {
        case COPY_TO_MODAL_OPEN: {
            return {
                ...state,
                copyToModalOpen: true,
                copyToModalParams: action.copyToModalParams,
            }
        }
        case COPY_TO_MODAL_CLOSE: {
            return {
                ...state,
                copyToModalOpen: false,
                selectedTransformValue: 0,
            }
        }
        case SET_TRANSFORMS_OPTIONS: {
            return {
                ...state,
                transformsOptions: action.transformsOptions,
            }
        }
        case SET_SELECTED_TRANSFORMS_VALUE: {
            return {
                ...state,
                selectedTransformValue: action.selectedTransformValue,
            }
        }
        default: {
            return state
        }
    }
}

/**
 * Import to Field Form Modal
 */

const initStateImportToFieldFormModal = {
    importToFieldFormModalOpen: false,
}

const modalImportToFieldForm = (
    state: tImportToFieldFormModalState = initStateImportToFieldFormModal,
    action: tImportToFieldFormModalActions
) => {
    switch (action.type) {
        case IMPORT_TO_FIELD_FORM_MODAL_OPEN: {
            return {
                ...state,
                importToFieldFormModalOpen: true,
                importToFieldFormModalParams: action.importToFieldFormModalParams,
            }
        }
        case IMPORT_TO_FIELD_FORM_MODAL_CLOSE: {
            return {
                ...state,
                importToFieldFormModalOpen: false,
            }
        }
        default: {
            return state
        }
    }
}

/**
 * Bundle Edit Warning Modal
 */
const initStateBundleEditWarning = {
    open: false,
}

const modalBundleEditWarning = (state = initStateBundleEditWarning, action: tBundleEditWarningActions) => {
    switch (action.type) {
        case BUNDLE_EDIT_WARNING_MODAL_OPEN:
            return {
                ...state,
                open: true,
            }
        case BUNDLE_EDIT_WARNING_MODAL_CLOSE:
            return {
                ...state,
                open: false,
            }
        default:
            return state
    }
}

/**
 * Leave Field Form Modal
 */

const initStateLeaveFieldForm = {
    isLeaveFieldFormModalOpen: false,
}

const modalLeaveFieldForm = (
    state: tLeaveFieldFormState = initStateLeaveFieldForm,
    action: tLeaveFieldFormModalActions
) => {
    switch (action.type) {
        case LEAVE_FIELD_FORM_MODAL_OPEN:
            return {
                ...state,
                isLeaveFieldFormModalOpen: true,
            }
        case LEAVE_FIELD_FORM_MODAL_CLOSED:
            return {
                ...state,
                isLeaveFieldFormModalOpen: false,
            }
        default:
            return state
    }
}

/**
 * Work Component Update Error Modal
 */

const initWorkComponentUpdateError = {
    errorMsg: "",
    isOpen: false,
}

const modalWorkComponentUpdateError = (
    state = initWorkComponentUpdateError,
    action: tEditWorkComponentModalActions
) => {
    switch (action.type) {
        case OPEN_WORK_COMPONENT_SAVE_ERROR_MODAL:
            return { ...state, errorMsg: action.errorMsg, isOpen: true }

        case CLOSE_WORK_COMPONENT_SAVE_ERROR_MODAL:
            return { ...state, errorMsg: "", isOpen: false }

        default:
            return state
    }
}

/**
 * Delete Confirmation Modal
 */

const initStateDeleteConfirmation = {
    open: false,
}

const deleteConfirmationModal = (state = initStateDeleteConfirmation, action: tDeleteConfirmationModalActions) => {
    switch (action.type) {
        case DELETE_CONFIRMATION_MODAL_OPENED:
            return { ...state, open: true }
        case DELETE_CONFIRMATION_MODAL_CLOSED:
            return { ...state, open: false }
        default:
            return state
    }
}

/**
 * Custom Form Error Modal
 */

const initCustomFormError = {
    open: false,
    errors: [],
    saveErrorType: "",
}

const modalCustomFormError = (state = initCustomFormError, action: tCustomFormModalActions) => {
    switch (action.type) {
        case CUSTOM_FORM_ERROR_MODAL_OPENED:
            return { ...state, open: true, errors: action.errors, saveErrorType: action.saveErrorType }
        case CUSTOM_FORM_ERROR_MODAL_CLOSED:
            return { ...state, open: false }
        default:
            return state
    }
}

/**
 * Generic Error Modal
 */

const initError = {
    open: false,
    errors: [],
    title: "",
    subtitle: "",
}

const modalError = (state = initError, action: tErrorModalActions) => {
    switch (action.type) {
        case ERROR_MODAL_OPENED:
            return { ...state, open: true, errors: action.errors, title: action.title, subtitle: action.subtitle }
        case ERROR_MODAL_CLOSED:
            return { ...state, open: false }
        default:
            return state
    }
}

/**
 * Confirm Change UI Modal
 */

const initCustomFormUIChange = {
    open: false,
}

const modalCustomFormUIChange = (state = initCustomFormUIChange, action: tCustomFormModalActions) => {
    switch (action.type) {
        case CUSTOM_FORM_UI_CHANGE_MODAL_OPENED:
            return { ...state, open: true }
        case CUSTOM_FORM_UI_CHANGE_MODAL_CLOSED:
            return { ...state, open: false }
        default:
            return state
    }
}

/**
 * Form Share Modal
 */

const initFormShare = {
    open: false,
}

const modalFormShare = (state = initFormShare, action: tFormShareModalActions) => {
    switch (action.type) {
        case FORM_SHARE_MODAL_OPENED:
            return {
                ...state,
                open: true,
                previousCollaborators: action.previousCollaborators,
                projectsList: action.projectsList,
                selectedForms: action.selectedForms,
                currentUserFullName: action.currentUserFullName,
                companyName: action.companyName,
                formType: action.formType,
                fetchError: action.fetchError,
                formSharePermissions: action.formSharePermissions,
                formShareVariants: action.formShareVariants,
            }
        case FORM_SHARE_MODAL_CLOSED:
            return { ...state, open: false }
        default:
            return state
    }
}

/**
 * Saved Filter modals
 */
const initCreateSavedFilter = {
    open: false,
}

const modalCreateSavedFilter = (state = initCreateSavedFilter, action: tCreateSavedFilterModalActions) => {
    switch (action.type) {
        case CREATE_SAVED_FILTER_MODAL_OPENED:
            return {
                ...state,
                open: true,
                filterState: action.filterState,
                page: action.page,
            }
        case CREATE_SAVED_FILTER_MODAL_CLOSED:
            return {
                ...state,
                message: undefined,
                open: false,
            }
        case CREATE_SAVED_FILTER_SET_FAILED:
            return {
                ...state,
                message: action.message,
            }
        default:
            return state
    }
}

const initDeleteSavedFilter = {
    open: false,
}

const modalDeleteSavedFilter = (state = initDeleteSavedFilter, action: tDeleteSavedFilterModalActions) => {
    switch (action.type) {
        case DELETE_SAVED_FILTER_MODAL_OPENED:
            return {
                ...state,
                open: true,
                id: action.id,
                page: action.page,
            }
        case DELETE_SAVED_FILTER_MODAL_CLOSED:
            return {
                ...state,
                message: undefined,
                open: false,
            }
        case DELETE_SAVED_FILTER_SET_FAILED:
            return {
                ...state,
                message: action.message,
            }
        default:
            return state
    }
}

const initAddRowsToWeeklyTKModal = {
    open: false,
}

const modalAddRowsToWeeklyTKModal = (
    state = initAddRowsToWeeklyTKModal,
    action: tAddRowsToWeeklyTKModalActions
) => {
    switch (action.type) {
        case ADD_ROWS_TO_WEEKLY_TK_MODAL_OPENED:
            return {
                ...state,
                open: true,
                resource: action.resource,
                resourceDisplayName: action.resourceDisplayName,
                context: action.context,
                colDefs: action.colDefs,
            }
        case ADD_ROWS_TO_WEEKLY_TK_MODAL_CLOSED:
            return {
                ...state,
                open: false,
            }
        default:
            return state
    }
}

const initAddCohortEmployeesModal = {
    open: false,
}

const modalAddCohortEmployeesModal = (
    state = initAddCohortEmployeesModal,
    action: tAddCohortEmployeesModalActions
) => {
    switch (action.type) {
        case ADD_COHORT_EMPLOYEES_OPENED:
            return {
                ...state,
                open: true,
                cohortId: action.cohortId,
                context: action.context,
                colDefs: action.colDefs,
            }
        case ADD_COHORT_EMPLOYEES_CLOSED:
            return {
                ...state,
                open: false,
            }
        default:
            return state
    }
}

/**
 * TK Sign Timecard Modal
 */

const initStateTKSignTimecard = {
    open: false,
}

const modalTKSignTimecard = (state = initStateTKSignTimecard, action: tTKSignTimecardModalActions) => {
    switch (action.type) {
        case TK_SIGN_TIMECARD_MODAL_OPENED:
            return { ...state, open: true }
        case TK_SIGN_TIMECARD_MODAL_CLOSED:
            return { ...state, open: false }
        default:
            return state
    }
}

const initSSOConfigurationConfirmationModal = {
    open: false,
    errorMessage: undefined,
}

const modalSSOConfigurationConfirmation = (
    state = initSSOConfigurationConfirmationModal,
    action: tSSOConfigurationConfirmationModalActions
) => {
    switch (action.type) {
        case SSO_CONFIGURATION_CONFIRMATION_MODAL_OPENED:
            return { ...state, open: true, errorMessage: action.errorMessage }
        case SSO_CONFIGURATION_CONFIRMATION_MODAL_CLOSED:
            return { ...state, open: false, errorMessage: undefined }
        default:
            return state
    }
}

const initWeeklyAddWorkShiftModal = {
    open: false,
    workShiftId: undefined,
}

const modalWeeklyAddWorkShift = (state = initWeeklyAddWorkShiftModal, action: tWeeklyAddWorkShiftModalActions) => {
    switch (action.type) {
        case WEEKLY_ADD_WORK_SHIFT_MODAL_OPENED:
            return {
                ...state,
                open: true,
                context: action.context,
            }
        case WEEKLY_ADD_WORK_SHIFT_MODAL_CLOSED:
            return { ...state, open: false, context: undefined, sourceData: undefined }
        default:
            return state
    }
}

const initAddEditWorkShiftModal = {
    open: false,
    workShiftId: undefined,
}

const modalAddEditWorkShift = (state = initAddEditWorkShiftModal, action: tAddEditWorkShiftModalActions) => {
    switch (action.type) {
        case ADD_EDIT_WORK_SHIFT_MODAL_OPENED:
            return {
                ...state,
                open: true,
                context: action.context,
                workShift: action.workShift,
                row: action.row,
            }
        case ADD_EDIT_WORK_SHIFT_MODAL_CLOSED:
            return { ...state, open: false, context: undefined, workShiftId: undefined, row: undefined }
        default:
            return state
    }
}

const initAddPicklistItemModal = {
    context: undefined,
    itemName: null,
    open: false,
    picklistId: null,
    picklistName: null,
    costCodeId: undefined,
    row: undefined,
}

const modalAddPicklistItem = (state = initAddPicklistItemModal, action: tAddPicklistItemModalActions) => {
    switch (action.type) {
        case ADD_PICKLIST_ITEM_MODAL_OPENED:
            return {
                ...state,
                context: action.context,
                itemName: action.itemName,
                open: true,
                picklistId: action.picklistId,
                picklistName: action.picklistName,
                costCodeId: action.costCodeId,
                row: action.row,
            }
        case ADD_PICKLIST_ITEM_MODAL_CLOSED:
            return { ...state, ...initAddPicklistItemModal }
        default:
            return state
    }
}

// QR Code modal

export type CicoQRCodeLightboxState = {
    open: boolean
    selectedEmployees: iEmployee[]
}

const initStateQRCodeModal = {
    open: false,
    selectedEmployees: [],
}
const modalQRCode = (state = initStateQRCodeModal, action: tCicoQRCodeLightboxModalAction) => {
    switch (action.type) {
        case CICO_QR_CODE_MODAL_OPENED:
            // Filter out employees without QR codes
            const filteredEmployees = action.selectedEmployees.filter(employee => !!employee.cico_qr_code)
            // order the list to be by last name, then first name
            filteredEmployees.sort((a, b) => {
                const lastNameComparison = a.last_name.localeCompare(b.last_name)
                return lastNameComparison !== 0 ? lastNameComparison : a.first_name.localeCompare(b.first_name)
            })
            return {
                ...state,
                open: true,
                selectedEmployees: filteredEmployees,
            }
        case CICO_QR_CODE_MODAL_CLOSED:
            return { ...state, open: false, selectedEmployees: initStateQRCodeModal.selectedEmployees }
        default:
            return state
    }
}

const modals = combineReducers({
    deleteConfirmationModal,
    modalAbsence,
    modalAddCohortEmployeesModal,
    modalAddPicklistItem,
    modalAddQuantity,
    modalAddRowsToWeeklyTKModal,
    modalAddWorker,
    modalAddWorkshift,
    modalAddEditWorkShift,
    modalBundleEditWarning,
    modalChangePassword,
    modalConfirmation,
    modalCopyTo,
    modalCreateSavedFilter,
    modalCsvError,
    modalCustomFormError,
    modalCustomFormGuestSave,
    modalCustomFormSave,
    modalCustomFormUIChange,
    modalDeleteSavedFilter,
    modalDeleteWorkshift,
    modalDuplicateInactiveWarning,
    modalDuplicateWorkshift,
    modalError,
    modalFormShare,
    modalGuestInfo,
    modalGuestInfoError,
    modalImportToFieldForm,
    modalLeaveFieldForm,
    modalProjectConfig,
    modalSignatureRequired,
    modalSSOConfigurationConfirmation,
    modalTKSignTimecard,
    modalQRCode,
    modalWalkthrough,
    modalWeeklyAddWorkShift,
    modalWorkComponent,
    modalWorkComponentUpdateError,
})

export default modals
