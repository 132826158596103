import { Dispatch } from "redux"
import { JsonPointer as jsonpointer } from "json-ptr"

/** Actions */
import { referenceableDataSet, sourceDataSet, sideRailSourceDataSet } from "./dashboard-data"
import { loadingComplete, loadingError, requestingData, setSaveStatus } from "./network-status"
import {
    loadAllEmployeeSchemasForProjectId,
    loadAllReferenceableData,
    loadSchemaStatusNames,
} from "../../cached-data/actions"
/** Utils */
import Rmbx from "../../util"
import { compareUnorderedNumberArrays } from "../../common/ts-utils"
import { getDummyData } from "../../common/ag-grid-utils"
import { getRowData } from "../../api"
import { isApplicableFilterValue } from "../../filters/utils"

/** Types */
import { tCachedResourceName } from "../../cached-data/types"
import { tReferenceableIdsState, tRelatedNames, tResourceObject, tSourceData, tSourceDataResponse } from "../types"
import { AsyncThunk, tResourceName } from "../../common/types"
import { getFlagEnabled } from "../../getFlagValue"
import { getObjectId } from "../../common/ag-grid-ts-utils"

/**
 * Finds the IDs of referenceable resources related to the row resource objects,
 * and combines them with the IDs in the given map of referenceable IDs. The new
 * object that is returned contains de-duped lists of referenceable resource IDs
 * keyed by referenceable resource name.
 *
 * Example return value:
 *     {'costCodes': [2, 1, 3], 'employees': [123, 456]}
 */
export const _getReferenceableIds = (
    rowResource: string,
    rowResourceObjects: tResourceObject[],
    relatedNames: tRelatedNames,
    referenceableIds: tReferenceableIdsState,
    referenceablesWithStringIds: tResourceName[]
): tReferenceableIdsState => {
    // Don't mutate the object passed in as a param
    const refIds = { ...referenceableIds } as tReferenceableIdsState

    // No data, so skip it
    if (rowResourceObjects.length < 1) {
        return refIds
    }

    // No resource IDs to get from this row resource, so skip it
    if (!relatedNames || !relatedNames[rowResource]) {
        return refIds
    }

    const fieldMapping = relatedNames[rowResource] || {}
    const fieldMappingKeys = Object.keys(fieldMapping) as tCachedResourceName[]

    fieldMappingKeys.forEach((referenceableResource: tCachedResourceName) => {
        const referenceableResourceFields = fieldMapping[referenceableResource] || []
        const relatedIds: Array<number | string> = rowResourceObjects.reduce(
            (acc: Array<number | string>, obj: tResourceObject) => {
                const ids = [] as Array<number | string>
                referenceableResourceFields.forEach((fieldName: string) => {
                    const val = jsonpointer.get(obj, fieldName)
                    // if the value is a list, check if it is a list of ids, or a list of objects with an id key
                    if (getFlagEnabled("WA-8743-cico-qr-pdfs") && Array.isArray(val)) {
                        val.forEach((v: any) => {
                            const itemId = getObjectId(v)
                            if (Rmbx.util.isNumber(itemId)) {
                                ids.push(itemId)
                            }
                        })
                    } else if (
                        val &&
                        !Rmbx.util.isNumber(val) &&
                        !referenceablesWithStringIds.includes(referenceableResource)
                    ) {
                        throw new Error(
                            `${fieldName} for referenceable resource ${referenceableResource} ` +
                                "did not resolve to a numeric primary key or a list of primary keys/objs with pks."
                        )
                    } else if (val) {
                        ids.push(val as number | string)
                    }
                })

                return [...acc, ...ids]
            },
            []
        )

        // Add the IDs to the object without creating duplicate entries
        const refIdsForResource = refIds[referenceableResource]
        if (refIdsForResource) {
            refIds[referenceableResource] = [...new Set([...refIdsForResource, ...relatedIds])]
        } else {
            refIds[referenceableResource] = [...new Set(relatedIds)]
        }
    })

    return refIds
}

const _loadReferenceablesForFilters = (
    refIds: Array<number | string>,
    filters: Array<number> | number
): Array<number | string> => {
    /*
     * If referenceableIds for projects is not the same as the projects in the project filter
     * Then turn referenceableIds["projects"] into a union of the 2 arrays
     */
    const filtersArray: Array<number> = Array.isArray(filters) ? filters : [filters]
    if (filtersArray.length > 0 && !compareUnorderedNumberArrays(refIds, filtersArray)) {
        return [...new Set([...refIds, ...filtersArray])]
    }
    return refIds
}

/**
 * Request any referenceable data that isn't in cache (or was invalidated)
 */
export const _requestReferenceableData = (
    referenceableIds: tReferenceableIdsState,
    filters: { [key: string]: any },
    downloadAllEmployeeSchemas: boolean,
    schemaNames: Array<string> = [],
    dispatch: Dispatch<any>,
    referenceablesWithStringIds: tResourceName[],
    referenceableQueryParamFn?: (filters: { [key: string]: any }) => Record<string, any>
) => {
    // This is a special case: sometimes we need the entire set of employee schemas
    // for shift extras, instead of fetching related IDs only
    if (downloadAllEmployeeSchemas) {
        const projectId = filters["project_id"] ? filters["project_id"] : "all"
        dispatch(loadAllEmployeeSchemasForProjectId(projectId))
    }

    if (isApplicableFilterValue(filters["schema_status_name"])) {
        dispatch(loadSchemaStatusNames(filters["schema_status_name"], schemaNames))
    }

    if (isApplicableFilterValue(filters["project_id"])) {
        referenceableIds["projects"] = _loadReferenceablesForFilters(
            referenceableIds["projects"] || [],
            filters["project_id"]
        )
    }

    const referenceableQueryParams = referenceableQueryParamFn ? referenceableQueryParamFn(filters) : {}
    // these will load any selected items if the page is refreshed, so the filter banner properly populates
    if (isApplicableFilterValue(filters["cost_item_id"])) {
        dispatch(loadAllReferenceableData("costItems", filters["cost_item_id"], false, referenceableQueryParams))
    }

    if (isApplicableFilterValue(filters["change_order_id"])) {
        dispatch(
            loadAllReferenceableData("changeOrders", filters["change_order_id"], false, referenceableQueryParams)
        )
    }

    if (isApplicableFilterValue(filters["employee_id"])) {
        dispatch(loadAllReferenceableData("employees", filters["employee_id"], false, referenceableQueryParams))
    }

    // Fetch the referenceable resources we need. The `loadAllReferenceableData` action
    // creator uses a cache-first approach, so we don't have to worry about re-fetching
    // data we already have in cache.
    const referenceableResources = Object.keys(referenceableIds) as tCachedResourceName[]
    referenceableResources.forEach((referenceableResource: tCachedResourceName) => {
        if (downloadAllEmployeeSchemas && referenceableResource === "employeeSchemas") {
            // No need for another API request for SE schemas if one has already been made.
            return
        }
        const ids = referenceableIds[referenceableResource]
        const searchByName = (referenceablesWithStringIds || []).includes(referenceableResource)
        if (ids && ids.length > 0) {
            dispatch(loadAllReferenceableData(referenceableResource, ids, searchByName, referenceableQueryParams))
        }
    })
}

/**
 * Returns the row/source data for a single resource. Source data is never
 * cached; it's always fetched from the API server.
 */
export const _requestRowResourceData = async (
    rowResource: tResourceName,
    queryParams: { [key: string]: any }
): Promise<tSourceDataResponse> => {
    try {
        const sourceData = await getRowData(rowResource, queryParams)

        return {
            resourceName: rowResource,
            data: sourceData.results,
        }
    } catch (error) {
        const e = error as any
        const message = e.response ? e.response.status + e.message : e.toString()

        throw new Error(message)
    }
}

/**
 * Given an array, appends promises that resolve to the dashboard's row/source
 * data, one for each resource.
 */
export const _appendSourceDataRequests = (
    promises: Promise<tSourceDataResponse>[],
    rowResources: tResourceName[],
    queryParams: { [key: string]: any }
): void => {
    for (const rowResource of rowResources) {
        let resourceQueryParams = queryParams
        if (queryParams.queryParamsByResource) {
            // A resource may have specific query param needs, and there's no need for a dashboard
            // that will request multiple resource to send the same large set of query params with each request.
            resourceQueryParams = {
                ...queryParams,
                ...(queryParams.queryParamsByResource[rowResource] ?? {}),
            }
            // 'queryParamsByResource' is meta config -- it can be removed now.
            delete resourceQueryParams.queryParamsByResource
        }
        // Request the resource data (using the resource-specific query params).
        promises.push(_requestRowResourceData(rowResource, resourceQueryParams))
    }
}

/**
 * Given an array of source data requests, resolves them and returns an array
 * of raw source data responses
 */
export const _resolveSourceDataRequests = async (
    sourceDataRequests: Promise<tSourceDataResponse>[]
): Promise<tSourceDataResponse[]> => {
    const sourceData = [] as tSourceDataResponse[]

    for (const request of sourceDataRequests) {
        const result = await request
        sourceData.push(result)
    }

    return sourceData
}

/**
 * Given an array of raw source data responses, extracts any referenceable IDs
 * referenced by the source data. Returns an object with the normalized source
 * data and referenceable IDs.
 */
export const _formatSourceData = (
    rowResources: tResourceName[],
    rawSourceData: tSourceDataResponse[],
    relatedNames: tRelatedNames,
    forceWeeklyPivotColumns: boolean,
    filters: { [key: string]: any },
    forceRefIds: tReferenceableIdsState = {},
    requiresCostCodeControlGrouping: boolean,
    referenceablesWithStringIds: tResourceName[]
): { sourceData: tSourceData; referenceableIds: tReferenceableIdsState } => {
    // we want to include any specified refIds from the dashboard so they are properly fetched
    let referenceableIds = { ...forceRefIds }
    const customDashboardData = {
        sourceData: {} as tSourceData,
        referenceableIds: {} as tReferenceableIdsState,
    }

    // Record both the row data and the referenceable resource IDs it needs.
    for (const resource of rawSourceData) {
        // The companyCrewTypes resource includes the cost code controls as children. To support grouping,
        // this needs to be flattened to put each cost code control on its own row, repeating the crew types
        if (resource.resourceName === "companyCrewTypes" && requiresCostCodeControlGrouping) {
            if (Array.isArray(resource.data) && resource.data.length > 0) {
                const flatSourceData: Array<any> = []
                resource.data.forEach((crew: any) => {
                    if (crew["cost_code_controls"] && crew["cost_code_controls"].length > 0) {
                        crew["cost_code_controls"].forEach((control: any) => {
                            const company_crew_and_cost_control_table_row = { ...crew }
                            company_crew_and_cost_control_table_row["cost_code_control"] = control
                            flatSourceData.push(company_crew_and_cost_control_table_row)
                        })
                    } else {
                        // For any crew types that don't have cost code controls, AG-Grid would show a blank row.
                        // since the "cost_code_control" attribute represents the content in this row,
                        // set the "cost_control_attribute" so that AG-Grid will show "N/A" instead of a blank row.
                        crew["cost_code_control"] = { name: "N/A" }
                        flatSourceData.push({ ...crew })
                    }
                })

                resource.data = flatSourceData
            }
        }

        customDashboardData.sourceData[resource.resourceName] = resource.data

        referenceableIds = _getReferenceableIds(
            resource.resourceName,
            resource.data,
            relatedNames,
            referenceableIds,
            referenceablesWithStringIds
        )
    }

    customDashboardData.referenceableIds = referenceableIds

    // Some dashboards like WTC and equipment tracking group cell data by
    // day of the week. We insert dummy data to ensure that columns for each
    // day will appear even if they happen to be empty.
    if (forceWeeklyPivotColumns) {
        const resourceName = rowResources[0]
        const sourceDataForResource = customDashboardData.sourceData[resourceName] || []
        customDashboardData.sourceData[resourceName] = [
            ...sourceDataForResource,
            ...getDummyData(filters.shift_start_time_0, filters.shift_start_time_1),
        ]
    }

    return customDashboardData
}

/**
 * If the dashboard navId is in the following list,
 * we're going to skip preloading the data if the required project filter has not been set.
 */
const skipPreloadIfFilterNotSet = new Set([
    "costcodes",
    "production",
    "project-members",
    "projectEquipment",
    "projectMaterial",
])

/**
 * Request all the data for a dashboard
 */
export const getCustomDashboardData = (
    dataNavId: string,
    rowResources: tResourceName[],
    relatedNames: tRelatedNames,
    filters: { [key: string]: any },
    fields: Array<string>,
    additionalQueryParams: { [key: string]: any },
    downloadAllEmployeeSchemas: boolean,
    forceWeeklyPivotColumns: boolean,
    requiresCostCodeControlGrouping: boolean,
    sideRailData?: boolean,
    forceRefIds?: tReferenceableIdsState,
    referenceablesWithStringIds: tResourceName[] = [],
    referenceableQueryParamFn?: (filters: { [key: string]: any }) => Record<string, any>
): AsyncThunk => async (dispatch, getState) => {
    if (skipPreloadIfFilterNotSet.has(dataNavId) && !isApplicableFilterValue(filters["project_id"])) return

    const promises = [] as Promise<tSourceDataResponse>[]

    dispatch(setSaveStatus("off"))
    dispatch(requestingData(promises))

    // Add requests for source data to the promise array
    _appendSourceDataRequests(promises, rowResources, {
        ...filters,
        field: fields,
        ...additionalQueryParams,
    })

    // Resolve the promises into source data (or bail out on error)
    let sourceData

    try {
        sourceData = await _resolveSourceDataRequests(promises)
    } catch (error) {
        if (promises !== getState().networkStatus?.promises) {
            dispatch(loadingError("Something went wrong, please try again later."))
        }
        return
    }

    /**
     * Ignore everything but the last set of requests, avoids race condition caused
     * by changing to a second dashboard when the first has not finished loading
     */
    if (promises !== getState().networkStatus?.promises) return

    // Format the raw source data, extracting any referenceable IDs in the process
    const customDashboardData = _formatSourceData(
        rowResources,
        sourceData,
        relatedNames,
        forceWeeklyPivotColumns,
        filters,
        forceRefIds,
        requiresCostCodeControlGrouping,
        referenceablesWithStringIds
    )
    /**
     * Use the redux middleware to fetch any referenceable data that isn't already in cache
     * We support both schemaNames and schemaName, but pass them as a list either way
     */
    let name
    let schemaNames = additionalQueryParams?.schema_names || []
    if (!schemaNames.length && (name = additionalQueryParams?.schema_name)) schemaNames = [name]

    /**
     * - Store both the row data and referenceable IDs in redux. Note that the network status
     * action only records the status of loading source data; referenceable data is fetched
     * in the background, so you need to use reselect selectors to determine its status.
     * - If the data is for the side rail, keep the data from the main custom-dashboard,
     * don't want to refetch it again when the side rail closes.
     */
    dispatch(
        sideRailData
            ? sideRailSourceDataSet(customDashboardData.sourceData)
            : sourceDataSet(customDashboardData.sourceData)
    )

    // Request for related referenceable data is made after source data has been fetched
    // and loaded into the Redux store.
    _requestReferenceableData(
        customDashboardData.referenceableIds,
        filters,
        downloadAllEmployeeSchemas,
        schemaNames,
        dispatch,
        referenceablesWithStringIds,
        referenceableQueryParamFn
    )
    dispatch(referenceableDataSet(customDashboardData.referenceableIds))
    dispatch(loadingComplete(dataNavId))
}

/**
 * Initialize a Server-Side Row Model AG Grid table. Mainly it sets the navId so that we
 * can display cute little saving/saved/save error icons in the status bar.
 * @param navId: The navigation ID of the table. Used to route messaging from the store to the
 * proper table
 */
export const initSSRMDashboard = (navId: string): AsyncThunk => async dispatch => {
    dispatch(setSaveStatus("off"))
    dispatch(loadingComplete(navId))
}
