import { iCurrentUser, tFeatures } from "../../../common/types"
import {
    stringColDefCreator,
    booleanColDefCreator,
    buttonColDefCreator,
    rowCountColDefCreator,
    employeeColDefCreator,
    checkboxColDefCreator,
} from "./standard-col-def-creators"
import RightRailButtonCellRenderer from "../cell-renderers/RightRailButtonCellRenderer"
import { tProject } from "../../../cached-data/types"
import { getCohortEmployeeSettings } from "./cohort-employees"
import { tResourceObject } from "../../../dashboard-data/types"
import { tContext } from "../types"
import { getFlagEnabled } from "../../../getFlagValue"

export const getCohortsSettings = (_: tFeatures, __: tProject, currentUser: iCurrentUser) => {
    const { user_role } = currentUser
    const canManage = user_role === "ADMIN"

    const textFieldOptions =
        currentUser && currentUser.text_field_options
            ? currentUser.text_field_options.filter(option => option.model === "Cohort")
            : []

    const textFieldOptionColDefs = textFieldOptions.map(option =>
        stringColDefCreator({
            headerName: option.label,
            field: `/custom_fields/${option.name}`,
            cellClass: option.read_only_in_app ? ["readonly"] : [],
            editable: !option.read_only_in_app,
        })
    )

    return {
        tableName: "Cohorts",
        navId: "cohorts",
        resources: ["cohorts"],
        relatedNames: getFlagEnabled("WA-8743-cico-qr-pdfs")
            ? {
                  cohorts: {
                      employees: ["/employees"],
                  },
              }
            : undefined,
        filters: [],
        additionalQueryParams: { include_hidden: "true" },
        omitFromUpdate: ["employees"],
        colDefs: [
            checkboxColDefCreator({}),
            stringColDefCreator({
                editable: canManage,
                field: "/name",
                headerName: "Name",
                sort: "asc",
            }),
            stringColDefCreator({
                editable: canManage,
                field: "/description",
                headerName: "Description",
                default: " ",
                width: 300,
            }),
            ...textFieldOptionColDefs,
            booleanColDefCreator({
                editable: canManage,
                headerName: "Is Hidden",
                field: "/is_hidden",
                minWidth: 100,
            }),
            rowCountColDefCreator({
                field: "/employees",
                headerName: "Members",
                default: [],
                // Have to use width because rowCountColDefCreator ignores minWidth
                // and without it, the members column is too narrow to show the
                // whole header
                width: 100,
            }),
            employeeColDefCreator({
                hide: true,
                default: [],
                field: "/employees",
            }),
            {
                ...buttonColDefCreator({ width: 150 }),
                cellRenderer: RightRailButtonCellRenderer,
                cellRendererParams: {
                    style: { verticalAlign: "unset" },
                    config: {
                        getSettings: getCohortEmployeeSettings,
                        useBasicHeader: false,
                    },
                    sideRailSettings: {
                        resource: "cohortEmployees",
                        title: (rowData: tResourceObject) => rowData.name,
                        primarySubtitle: (rowData: tResourceObject) => rowData.description,
                    },
                },
                headerName: "",
                hide: !canManage,
            },
        ],
        gridSettings: {
            rowHeight: 45,
            rowSelection: "multiple",
            defaultColDef: {
                editable: false,
            },
        },
        otherSettings: {
            enableSearchBar: true,
            buttons: {
                row: canManage
                    ? [
                          {
                              label: "Duplicate",
                              icon: "copy",
                              action: "duplicateRow",
                              disabled: (context: tContext) => context.selectedRows.length > 1,
                              tooltip: (context: tContext) =>
                                  context.selectedRows.length > 1 && "You may only duplicate one row at a time",
                          },
                          {
                              label: "Delete",
                              icon: "delete",
                              action: "deleteSelectedRows",
                          },
                          ...(getFlagEnabled("WA-8743-cico-qr-pdfs")
                              ? [
                                    {
                                        label: "Print QR Code PDF",
                                        action: "openPrintQRModal",
                                        disabled: (context: tContext) => {
                                            // cohort employees don't have cico qr code data stored on them
                                            // so correlate the row's employee ids with the referenced employee data
                                            // and make sure at least one employee has a qr code
                                            const relatedEmpData = context?.referenceableData?.employees ?? {}
                                            return context.selectedRows.every(row =>
                                                row.employees?.every(
                                                    (cohortEmp: { id: number }) =>
                                                        !relatedEmpData?.[cohortEmp.id]?.cico_qr_code
                                                )
                                            )
                                        },
                                        tooltip: (context: tContext) => {
                                            const relatedEmpData = context?.referenceableData?.employees ?? {}
                                            if (
                                                context.selectedRows.every(row =>
                                                    row.employees?.every(
                                                        (cohortEmp: { id: number }) =>
                                                            !relatedEmpData?.[cohortEmp.id]?.cico_qr_code
                                                    )
                                                )
                                            )
                                                return "None of the selected cohorts have employees with QR codes"
                                            return "Print QR code PDFs for selected cohorts"
                                        },
                                    },
                                ]
                              : []),
                      ]
                    : [],
                table: canManage
                    ? [
                          {
                              label: "Create Cohort",
                              icon: "add",
                              action: "addNewRow",
                          },
                      ]
                    : [],
            },
        },
    }
}
